import { modalController, IonContent, IonHeader, IonIcon, IonPage,IonSelect, IonTitle, IonSelectOption, IonButton, IonInput, IonItem, IonList} from "@ionic/vue";
import {Vue,Options} from 'vue-class-component';

@Options({
  name: "NewApplicationPage",
  components: { IonContent, IonHeader, IonPage, IonSelect, IonTitle, IonSelectOption, IonButton, IonIcon, IonInput, IonItem, IonList },
  inheritAttrs: false
})

export default class NewApplicationPage extends Vue {
  modalCtrl = modalController;
  token = JSON.parse(localStorage.getItem('user_token'));
  templates = [];
  selected_template_id = 0;
  selected_template = null;
  error_firstname= false;
  error_lastname= false;
  error_email= false;
  invalid_email = false;
  firstname = '';
  lastname = '';
  email = '';
  phone = '';
  
  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.loadTemplates()
  }

  /**
   * Load templates
   */
   async loadTemplates(){
    await this.$axios.get(this.$baseUrl + "/api/templates", {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => {
      if(res) {
        this.templates = res['data'].data;
      }
     }).catch(error => {
      console.error("There was an error!", error)
    })
    
    if(this.templates.length){
      this.selected_template_id = this.templates[0].id;
      this.selected_template = this.templates[0];
      this.prepareCurrentTemplate();
    }
  }

  /**
   * Prepare current template before display
   */
  prepareCurrentTemplate(){
    if( this.selected_template.collect_custom && 
      this.selected_template.collect_custom_value &&
      this.selected_template.collect_custom_value != ""
    ){
      this.selected_template.collect_custom_values_arrray = this.selected_template.collect_custom_values.split("\n");
    }else{
      this.selected_template.collect_custom_values_arrray = null
    }
  }

  /**
   * Get item by ID in an arrray
   */
  get_item_by_id(items, searched_id) {
    for (var i = 0; i < items.length; i++) {
      if (items[i].id === searched_id) {
        return items[i];
      }
    }
  }

  /**
   * Triggered when template is changed
   */
  changeTemplate(){
    this.selected_template = this.get_item_by_id(this.templates,this.selected_template_id);
  }

  /** 
   * Check if the email is valid or not
   */
  validateEmail(value) {
    //eslint-disable-next-line
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ;
  } 

  /* 
   * Creer a dossier
   */
  addDossier() {
    if(!(this.firstname )){
      this.error_firstname = true;
      return;
    }
    else {
      this.error_firstname = false;
    }
    if(!(this.lastname )){
      this.error_lastname = true;
      return;
    }
    else {
      this.error_lastname = false;
    }
    if(!(this.email)){
      this.error_email = true;
      return;
    }
    else {
      this.error_email = false;
      if (this.validateEmail(this.email)) {
        this.invalid_email = false;
      } else {
        this.invalid_email = true;
        return;
      }
    }

    let contact = {
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        custom:null,
        phone:null,
        company:null
    }

    if(this.selected_template.collect_custom){
      contact.custom = this.custom;
    }
    if(this.selected_template.collect_company){
      contact.company = this.company;
    }
    if(this.selected_template.collect_phone){
      contact.phone = this.phone;
    }

    let newDossier = {
        contact: contact,
        template_id: this.selected_template.id,
        notify_recipient: true
    }

    this.$axios.post(this.$baseUrl + "/api/dossiers", newDossier, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
      }).then(data => {
      if(data) {
        this.modalCtrl.dismiss(true);
      }
     }).catch(error => {
      console.error("There was an error!", error)
    })
  }

  /* 
   * Close the modal
   */
  close() {
    this.modalCtrl.dismiss()
  } 
}
