
import { modalController, IonContent, IonItem, IonList, IonSelect, IonSelectOption, IonInput, IonIcon, IonCheckbox, IonButton } from '@ionic/vue';
import { Vue, Options } from 'vue-class-component';

@Options ({
  name: "FilterPage",
  components: { IonContent, IonItem, IonList, IonSelect, IonSelectOption, IonInput, IonButton, IonCheckbox, IonIcon },
  props: {
    status: { type: String },
    template_id: { type: Number}
  },
  inheritAttrs: false
})
export default class FilterPage extends Vue {
  modalCtrl= modalController;
  token  = JSON.parse(localStorage.getItem('user_token'));
  templates = [];
  statuses = [];
  current_status = "";
  current_template_id = "";
  filter_all = "-ALL-";
  tags = [];
  filter_start_date = new Date();
  filter_end_date= new Date();
  filter_range=true;
  current_tag_id = "";

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.loadTemplates();
    this.loadStatuses();
    this.loadTags();
    let dd = String(this.filter_end_date.getDate()).padStart(2, '0');
    let mm = String(this.filter_end_date.getMonth() + 1).padStart(2, '0');
    let yyyy = this.filter_end_date.getFullYear();
    let treeMonthBefor = mm - 3 ;
    let year = 0;
    if(treeMonthBefor <= 0) {
      treeMonthBefor = String(treeMonthBefor + 12).padStart(2, '0');
      year = this.filter_end_date.getFullYear() - 1;
    }
    treeMonthBefor= String(treeMonthBefor).padStart(2, '0')
    this.filter_end_date = yyyy + '-' + mm + '-' + dd;
    this.filter_start_date = year + '-' + treeMonthBefor + '-' + dd;
  }

  /**
   * Load status
   */
  loadStatuses(){
    this.statuses = ['0_not_started','1_in_progress','3_submitted','4_pre_approved','5_closed'];
    this.current_status = this.status;
  } 

  /**
   * Load tags
   */
  loadTags(){
    this.$axios.get(this.$baseUrl + "/api/tags", {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
      })
    .then(data => {
      if(data) {
        this.tags = data['data'].data;
      }
     }).catch(error => {
      console.error("There was an error!", error);
    });
  }

  /**
   * Load templates
   */
  async loadTemplates(){
    await this.$axios.get(this.$baseUrl + "/api/templates", {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
      })
    .then(data => {
      if(data) {
        this.templates = data['data'].data;
      }
     }).catch(error => {
      console.error("There was an error!", error);
    });
  }

  /** 
   * Apply filter
   */
  applyFilter(current_status,current_template_id,current_tag_id,start_date,end_date) {
    if(this.disable_filter_range==true) {
      start_date = null;
      end_date = null;
    }
    this.modalCtrl.dismiss({status:current_status,template_id:current_template_id,tag:current_tag_id,start_date:start_date,end_date:end_date});
  }

  /** 
   * Close modal
   */
  close() {
    this.modalCtrl.dismiss();
  }
}