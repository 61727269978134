import { useRoute } from 'vue-router';
import { Vue, Options } from 'vue-class-component';
import { loadingController, IonContent, IonHeader, IonIcon, IonBackButton, IonTitle, IonButton, IonTextarea} from "@ionic/vue";

@Options ({
  name: "inboxPage",
  components: { IonHeader, IonContent, IonIcon, IonBackButton, IonTitle, IonButton, IonTextarea },
  inheritAttrs: false
})
export default class InboxClass extends Vue{
  loadingCtrl = loadingController
  token = JSON.parse(localStorage.getItem('user_token'));
  messages = [];
  message = '';
  username;
  loading = false;
  myLoading;
  stakeholders = [];
  stakeholder;
  route = useRoute(); 
  dossier_id = this.route.params.id;

  /**
   * Called after the component has been mounted
   */
  mounted(){
    this.showLoader();
    this.load_messages();
  }

  /** 
   * Show loader
   */
  async showLoader() {
    this.loading = true;
    this.myLoading = await this.loadingCtrl.create({
      spinner: null,
      cssClass: 'custom-loading',
      duration: 1000
    });
    await this.myLoading.present()
    setTimeout(() => {
      this.$refs.content.$el.scrollToBottom(500)
    }, 1000);
  }
  
  /** 
   * Load messages
   */
  async load_messages() {
     await this.$axios.get(this.$baseUrl + "/api/messages/" + this.dossier_id , {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
      }).then(data => {
        if (data) {
          this.messages = data['data'].data;
          this.stakeholders = this.$store.state.current_dossier.stakeholders; 
          this.message = "@" + this.stakeholders[0].mention_name + "  "; 
          this.stakeholder = this.message;
          if (this.loading) {
            this.loadingCtrl.dismiss();
            this.loading = false;
          }
          setTimeout(() => {
            this.$refs.content.$el.scrollToBottom(500)
          }, 1000);
        }
      }).catch(error => {
        console.error("There was an error!", error);
    }); 
  }

  /** 
   * Post message
   */
  postMessage() {
    if(this.message !== this.stakeholder) {
      let postMessage = "body=" + this.message + "&dossier_id" + this.dossier_id + "&recipients: ['*']";
      this.$axios.post(this.$baseUrl + "/api/messages/" + this.dossier_id, postMessage, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + this.token
        }
      }).then(() => {
        this.message = '';
        this.load_messages();
        this.loadingCtrl.dismiss();
        this.loading = false;
      }).catch(error => {
        console.error("There was an error!", error);
      });
    }
  }
}