import { modalController, IonContent, IonIcon, IonButton, IonTextarea } from '@ionic/vue';
import { Vue, Options } from 'vue-class-component';

@Options ({
  name: "CommentPage",
  components: { IonContent, IonButton, IonIcon, IonTextarea },
  inheritAttrs: false
})
export default class Comment extends Vue{
  modalCtrl = modalController;

  /** 
   * Close modal
   */ 
  close() {
    this.modalCtrl.dismiss()
  }

  /** 
   * Submit comment 
   */ 
  submit() {
    if(this.comment.trim() != ""){
      this.modalCtrl.dismiss(this.comment)
    }
  }
}


