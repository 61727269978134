

import { loadingController, IonHeader, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonButton, IonMenuButton, IonMenu} from "@ionic/vue"
import { Vue, Options } from 'vue-class-component';


@Options ({
  name: "MessagingPage",
  components: { IonHeader, IonPage, IonTitle,IonRefresher, IonRefresherContent, IonButton, IonMenuButton, IonMenu, IonIcon },
  inheritAttrs: false
})
export default class MessagingClass extends Vue{
  token = JSON.parse(localStorage.getItem("user_token"));
  myLoading= null;
  loadingCtrl = loadingController;
  loading = false;
  conversations = [];

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.loadInitial()
  }

  /** 
   * Get users list
   */
  getUsersList (event) {
    event.target.disabled = true
    this.loadInitial()
    setTimeout(() => {
      event.target.complete()   
    }, 1500)
    setTimeout(() => {
      event.target.disabled = false
    }, 1500)
  }

  /** 
  * Show Loader
  */
  async showLoader() {
    this.loading = true
    this.myLoading = await this.loadingCtrl.create({
      spinner: null,
      cssClass: "custom-loading",
      duration: 1000
    })
    await this.myLoading.present()
  }

  /** 
   * Truncate string for the body of message
   */
  truncate_string (yourString, maxLength) {
    // trim the string to the maximum length
    const current_max = maxLength > yourString.length ? yourString.length : maxLength 
    let res = yourString.substr(0, current_max)
    if (current_max != yourString.length ) {
      res  += "..."
    }
    return res
  }

  /* 
   * Load conversations
   */
  async load_conversations() {
    await this.$axios.get(this.$baseUrl + "/api/dossiers/having-messages", {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => {
      if (res) {        
        this.conversations = res['data'].data
        if (this.loading) {
          this.loadingCtrl.dismiss()
          this.loading = false
        }
      }
    }).catch(error => {
      console.error("There was an error!", error)
    })
  }

  /** 
   * Load initial
   */
  loadInitial () {
    if (this.conversations.length == 0) {
      this.showLoader()
    }
    this.load_conversations()
  }

  /** 
  * Go to inbox
  */
  goMessaging (dossier) {
    this.$store.commit('SET_DOSSIER', dossier)
    this.$router.push({path :"/inbox/" + dossier.id})
  }
}

