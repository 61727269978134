<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button mode="ios" text=""  default-href="" v-on:click="$router.back()"></ion-back-button>
      </ion-buttons>
      <ion-title mode="ios">{{dossier.contact?.full_name}}</ion-title>
      <ion-buttons slot="end">
        <ion-button v-on:click="moreOptions()">
          <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-top">
    <div class="first" v-if="loading==false">
      <ion-row>
        <ion-col size="6">
          <div class="ion-text-left card fx">
            <h4>{{ $t('details_template') }}</h4>
            <ion-label>{{dossier.title}}</ion-label>
          </div>
        </ion-col>
        <ion-col size="6">
          <div class="ion-text-left card status fx">
            <h4>{{ $t('details_status') }}</h4>
            <div class="jc-between">
              <span :style="{'background-color': dossier.status_color}" class="dot"> </span>           
            </div>
            <ion-label>{{dossier.status_string_full}}</ion-label>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6" v-on:click="goStage()">
          <div class="first card fx">
            <div class="flex al-center jc-between">
              <h4>{{ $t('details_progress')}}</h4>
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
            <ion-label v-if="stage_string">{{stage_string}}</ion-label>
            <ion-label v-else >{{  $t('not_defined_yet') }}</ion-label>
          </div>
        </ion-col>
        <ion-col size="6" v-on:click="goTodo()">
          <div class="first card fx">
            <div class="flex al-center jc-between">
              <h4>{{ $t('details_todos') }}</h4>
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
            <ion-label v-if="todos_string != '0/0'">{{todos_string}}</ion-label>
            <ion-label v-else >{{  $t('not_defined_yet') }}</ion-label>
          </div>
        </ion-col>
      </ion-row>
      <ion-row v-if="dossier.scoring != null || dossier.scoring != undefined">
        <ion-col size="12">
          <div class="ion-text-left card fx">
            <h4>{{ $t('detail_scoring') }}</h4>
            <ion-progress-bar :value="scoring" v-if="dossier.scoring <= 30" style="--progress-background: rgb(252, 97, 128)"></ion-progress-bar>
            <ion-progress-bar :value="scoring" v-else-if="dossier.scoring > 30 && dossier.scoring < 60" style="--progress-background: rgb(255, 182, 77)"></ion-progress-bar>
            <ion-progress-bar :value="scoring" v-else style="--progress-background: rgb(147, 190, 82)"></ion-progress-bar>
            <h5>{{Number.parseFloat(scoring * 100).toFixed(0)}}%</h5>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <div class="ion-padding-top">
      <div v-for="dossier_item in dossier.dossier_items" :key="dossier_item" class="content-holder">
        <ion-row  v-if="dossier_item.type !=='separator'" v-on:click="openDossierItem(dossier_item)">
          <ion-col size="9">
            <h5>
              <ion-icon v-if="!dossier_item.visible_to_client" name="lock-closed-outline"></ion-icon> 
              {{dossier_item.title}}
            </h5> 
          </ion-col>
          <ion-col size="2">
            <div :style="{'background-color': dossier_item.status_color}" class="dot"> </div>
          </ion-col>
          <ion-col size="1">
            <ion-icon name="chevron-forward-outline" ></ion-icon>    
          </ion-col>
        </ion-row>
        <ion-row  v-if="dossier_item.type =='separator' && dossier_item.separator_type =='free_text' " >
          <ion-col size="12">
            <p class="free_text" v-html='dossier_item.comments' ></p>
          </ion-col>
        </ion-row>
        <ion-item-divider mode="ios" v-if="dossier_item.type =='separator' && dossier_item.separator_type =='heading'">
          <ion-label>
            <ion-icon v-if="!dossier_item.visible_to_client" name="lock-closed-outline"></ion-icon> 
            {{dossier_item.title}}
          </ion-label>
        </ion-item-divider>
      </div>
    </div>
  </ion-content>
</template>

<script src="./../components/details/detail.js"></script>
<style scoped src="./../components/details/detail.css"></style>