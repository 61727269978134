<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button mode="ios" text="" default-href="" v-on:click="$router.back()"></ion-back-button>
      </ion-buttons>
      <ion-title mode="ios">{{ $t('todos_header')}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" scroll-y="false">
    <div class="the-list">
      <ion-item v-on:click="updateToDo(item)" v-for="item in todos" :key="item" lines="none">
        <ion-label>{{item.title}}</ion-label>
        <div  class="end-slot flex al-center" slot="end">
          <ion-icon name="checkmark-circle" v-if="item.done == 1" color="success"></ion-icon>
          <ion-icon name="ellipse-outline"  v-if="item.done == 0"></ion-icon>
        </div>
      </ion-item>
    </div>
    <div class="ion-text-center no-item-image"  v-if="!todos || !todos.length">
      <img src="../assets/imgs/no-item.png" alt="" style="width:40%">
      <p>
        {{$t('todos_no_item_found') }}
      </p>
    </div>
  </ion-content>
</template>

<script src="../components/todo/todo.js"></script>
<style scoped src="../components/todo/todo.css"></style>