<template>
  <ion-content>
  <div class="backdrop" v-on:click="close()"></div>
  <div class="group">
    <ion-list>
      <div class="list-header flex jc-between al-center">
        <div>
          {{ $t('new_application_title')}}
        </div>
        <div v-on:click="close()">
          <img src="../assets/imgs/oclose.svg" alt="">
        </div>
      </div>
      <ion-item lines="none">
        <ion-select v-model="selected_template_id" interface="action-sheet" mode="ios" @ionChange="changeTemplate()">
          <ion-select-option v-for=" template in templates" :value="template.id" :key="template">{{template.title}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-input v-model="firstname" type="text" :placeholder="$t('new_application_firstname')"></ion-input>
      </ion-item>
      <p class="error"  v-if="error_firstname==true">{{ $t('error_field' ) }}</p>
      <ion-item lines="none">
        <ion-input v-model="lastname" type="text" :placeholder="$t('new_application_lastname')"></ion-input>
      </ion-item>
      <p class="error"  v-if="error_lastname==true">{{ $t('error_field' ) }}</p>
      <ion-item lines="none">
        <ion-input v-model="email" type="email" :placeholder="$t('new_application_email')" ></ion-input>
      </ion-item> 
      <p class="error"  v-if="error_email==true">{{ $t('error_field' ) }}</p>
      <p class="error"  v-if="invalid_email==true">{{ $t('invalid_email') }}</p>
      <ion-item lines="none" v-if="selected_template && selected_template.collect_phone">
        <ion-input v-model="phone" type="tel" :placeholder="$t('new_application_phone')"></ion-input>
      </ion-item>
      <ion-item lines="none" v-if="selected_template && selected_template.collect_company">
        <ion-input v-model="company" type="text" :placeholder="$t('new_application_company')"></ion-input>
      </ion-item>
      <ion-item lines="none" v-if="selected_template && selected_template.collect_custom">
        <ion-input v-model="custom" type="text" :placeholder="selected_template.collect_custom_label" v-if="!selected_template.collect_custom_values_arrray"></ion-input>
        <ion-select v-model="custom" interface="action-sheet" mode="ios" @ionChange="template.changeTemplate()" v-if="selected_template.collect_custom_values_arrray">
          <ion-select-option v-for=" custom_value in selected_template.collect_custom_values_arrray" :value="custom_value" :key="custom_value">{{custom_value}}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <ion-button expand="full" color="success" v-on:click="addDossier()">{{ $t('new_application_button')}}</ion-button>
  </div>
</ion-content>
</template>

<script src="../components/newApplication/newApplication.js"></script>
<style scoped  src="../components/newApplication/newApplication.css"></style>