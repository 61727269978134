<template>
  <ion-content>
    <div class="content">
      <ion-grid style="height: 100%">
        <ion-row class="ion-justify-content-center ion-align-items-center" style="height: 100%">
          <div class="holder">
            <div class="logo-holder ion-text-center flex al-center jc-center">
              <img src="./../assets/imgs/logo.svg" alt=""/>
            </div>
            <div class="inside-content">
              <div class="title">
                <h3> {{ $t( 'login_login') }} </h3>
                <div class="head-styler flex">
                  <div class="st1"> </div>
                  <div class="st2"></div>
                </div>
                <p>{{ $t( 'tilte_2fa') }} </p>
              </div>
              <div class="the-form">
                <ion-item lines="none">
                  <ion-input type="number" v-model="tokenAuth" :placeholder="$t('token_auth')"></ion-input>
                </ion-item>
              </div>
              <p class="ion-text-center error"  v-if="error_login==true">{{ $t('token_error_message' ) }}</p>
              <div class="btn-holder ion-padding-vertical">
                <ion-button expand="block" v-on:click="goHome(tokenAuth)">
                {{ $t('login_check_2fa') }}
                </ion-button>
              </div>  
              <div class="description">
                <a class="ion-text-wrap" v-on:click="back()">
                  {{ $t('connexion' ) }}
                </a>
              </div>
            </div>
          </div>
        </ion-row>
      </ion-grid>
    </div>
  </ion-content>
</template>

<script src="../components/requestToken2FA/requestToken2FA.js"></script>
<style scoped  src="../components/requestToken2FA/requestToken2FA.css"></style>
