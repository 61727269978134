<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button mode="ios" text="" default-href="" v-on:click="$router.back()"></ion-back-button>
      </ion-buttons>
      <ion-title mode="ios">{{$t('infos_header')}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="the-list ion-text-center">
      <h3 >{{fullname}}</h3>
      <h4>{{email}}</h4>
      <p v-if="phone">{{phone}}</p> 
      <p v-if="company">{{company}}</p>
      <p v-if="customFiels">{{customFiels}}</p>
    </div>
  </ion-content>
</template>

<script src="../components/infos/infos.js"></script>
<style scoped src="../components/infos/infos.css"></style>