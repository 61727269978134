import { createApp } from "vue"
import { createStore } from 'vuex'
import { IonicVue } from "@ionic/vue"
import App from "./App.vue"
import router from "./router"
import axios from "axios"
import dateFormat from "dateformat";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css"
import "@ionic/vue/css/structure.css"
import "@ionic/vue/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css"
import "@ionic/vue/css/float-elements.css"
import "@ionic/vue/css/text-alignment.css"
import "@ionic/vue/css/text-transformation.css"
import "@ionic/vue/css/flex-utils.css"
import "@ionic/vue/css/display.css"

/* Theme variables */
import "./theme/variables.css"

/* Icon Ionic */
import { addIcons } from 'ionicons';
import * as allIcons from 'ionicons/icons';

/* i18next  */
import i18n from "./i18n"
import './registerServiceWorker'

/* Create a new store instance. */
const store = createStore({
  state() {
    return {
      current_dossier: null,
      user: null
    }
  },
  mutations: {
    SET_DOSSIER(state,val) {
      state.current_dossier=val
    },
    SET_USER(state,val) {
      state.user=val
    }
  }
})

/*  Create a root component */
const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(router)
  .use(store)


/* Globale variable */
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$mobile = "/mobile";
app.config.globalProperties.$baseUrl = "https://app.clustdoc.com";
app.config.globalProperties.$router = router;
app.config.globalProperties.$dateFormat = (dateChangeFormat) => {
let date = new Date(Date.parse(dateChangeFormat))
  return dateFormat(date, 'mmm dd, yyyy');
};

/* root route  */
router.isReady()
  .then(() => {
    app.mount("#app")
})

/*  add icons */
const currentIcons = Object.keys(allIcons).map(i => {
  const key = i.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
  if(typeof allIcons[i] === 'string') {
    return {
      [key]: allIcons[i],
    }
  }
  return {
    ['ios-' + key]: allIcons[i].ios,
    ['md-' + key]: allIcons[i].md,
  };
});
const iconsObject = Object.assign({}, ...currentIcons);
addIcons(iconsObject);