export default {
  "dossier_item_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers trouvés"])},
  "more_options_application_reopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier réouvert"])},
  "more_options_application_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier fermé"])},
  "more_options_reminder_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel envoyé"])},
  "todos_no_item_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun élément"])},
  "new_application_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau dossier"])},
  "stages_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancement"])},
  "stages_no_item_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'étapes d'avacement définies "])},
  "teams_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos équipes"])},
  "todos_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo liste"])},
  "menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide?"])},
  "menu_inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "menu_teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer d'équipe"])},
  "messaging_no_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conversation"])},
  "messaging_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "more_options_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturer le dossier"])},
  "more_options_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le dossier"])},
  "more_options_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos du dossier"])},
  "home_no_item_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier trouvé"])},
  "more_options_messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les messages"])},
  "home_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche ... "])},
  "more_options_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un rappel"])},
  "home_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "not_defined_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore défini(e)"])},
  "more_options_reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réouvrir le dossier"])},
  "home_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
  "more_options_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More options"])},
  "inbox_new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your message"])},
  "new_application_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le dossier"])},
  "inbox_no_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun message trouvé"])},
  "new_application_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société du destinataire"])},
  "inbox_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "new_application_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "inbox_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boîte de réception"])},
  "new_application_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du destinataire"])},
  "infos_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
  "new_application_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom du destinataire"])},
  "login_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "tilte_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre code d'authentification"])},
  "token_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token d'authentification"])},
  "error_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])},
  "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email n'est pas correct"])},
  "new_application_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "login_error_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token incorrect"])},
  "login-via-emergency-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareil perdu?"])},
  "login_check_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "login_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email et le mot de passe ne correspondent pas"])},
  "login_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ravis de vous revoir"])},
  "login_login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignez vos informations de connexion"])},
  "login_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
  "login_sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je me connecte"])},
  "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "token_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le token n'est pas valide"])},
  "login_sign_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez besoin d'un compte Clustdoc pour vous connecter. Créez un compte depuis un ordinateur sur clustdoc.com."])},
  "menu_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
  "filters_5_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturé"])},
  "filters_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
  "filters_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le filtre"])},
  "filters_status_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les statuts"])},
  "filters_status_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "filters_template_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédure"])},
  "filters_templates_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les procédures"])},
  "filters_tags_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les étiquettes"])},
  "filters_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "filters_title_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer l'écran par"])},
  "comment_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez un commentaire ci-dessous"])},
  "details_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancement"])},
  "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "details_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédure"])},
  "details_todos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo liste"])},
  "dossier_item_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approuver"])},
  "dossier_item_cancel_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'approbation"])},
  "dossier_item_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenter"])},
  "dossier_item_no_uploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document disponible pour le moment."])},
  "filters_0_not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas commencé"])},
  "detail_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring"])},
  "filters_1_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "filters_3_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réceptionné"])},
  "filters_4_pre_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré approuvé"])}
}