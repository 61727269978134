import { menuController, IonContent, IonMenuToggle, IonIcon, IonMenu, IonItem, IonList, IonRouterOutlet, IonCol } from '@ionic/vue';
import { Vue, Options } from 'vue-class-component';
import { folderOpenOutline, mailOutline, peopleOutline, helpCircleOutline, logOutOutline} from 'ionicons/icons';

@Options ({
  name: "MenuPage",
  components: {IonContent, IonMenuToggle, IonMenu, IonIcon, IonItem, IonList, IonRouterOutlet, IonCol },
})
export default class MenuClass extends Vue {
  menuCtrl = menuController
  user_name = JSON.parse(localStorage.getItem('user_name'));
  user_email = JSON.parse(localStorage.getItem('user_email'));
  user_photo_url = JSON.parse(localStorage.getItem('user_photo_url'));
  sideMenu =
  [
    {
      title: "menu_applications",
      action: "Home",
      icon: folderOpenOutline
    },
    {
      title: "menu_inbox",
      action: "Messaging",
      icon: mailOutline
    },
    {
      title: "menu_teams",
      action: "Teams",
      icon: peopleOutline
    },
    {
      title: "menu_help",
      action: "help",
      icon: helpCircleOutline
    },
    {
      title: "menu_logout",
      action: "logout",
      icon: logOutOutline
    }
  ];
  
  /* 
   * Log Out
   * Remove the info stocked
   */
  logout(){
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_photo_url');
    localStorage.removeItem('2fa');
    this.$store.state.user = null;
    // Go to the login page
    window.location = this.$mobile
      
  }

  /** 
   * Execute action in the menu
   */
  executeAction(action) {
    switch (action) {
      case 'Home':
      case'Messaging':
      case 'Teams':
        this.$router.push({name: action});
        break;

      case 'help':
        var url = "https://support.clustdoc.com";
        window.open(url) ;
      break;

      case 'logout':
        this.logout();
        break;
    
      default:
        break;
    }
  }
}

