<template>
  <ion-content>
    <div class="backdrop" v-on:click="close()"></div>
    <div class="group ion-text-center">
      <h4>{{$t('comment_title')}}</h4>
      <div class="form">
        <ion-textarea rows="5"  v-model="comment" ></ion-textarea>
      </div>
      <div class="btns-holder">
        <ion-row>
          <ion-col size="6">
            <ion-button v-on:click="close()" expand="block" id="cancel">
              {{$t('comment_cancel')}}
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button v-on:click="submit()" expand="block" id="ok">
              {{$t('comment_submit')}}
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-content>
</template>

<script src="../components/comment/comment.js"></script>
<style scoped src="../components/comment/comment.css"></style>