<template>
  <ion-menu contentId="main-content">
    <ion-content scroll-y="false">
      <div class="header">
        <div class="avatar">
          <img :src="user_photo_url"/>
        </div>
        <p>
          {{user_name}}<br>
          <span>
            {{user_email}}
          </span>
        </p>
      </div>
      <ion-list v-for=" menu in sideMenu" :key="menu" lines="inset">
        <ion-menu-toggle auto-hide="false">
          <ion-item  v-on:click="executeAction(menu.action)" routerDirection="forward" menuClose> 
            <ion-icon :src="menu.icon" slot="start"></ion-icon>
            {{ $t(menu.title) }}
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</template>

<script src="./../components/menu/menu.js"></script>
<style scoped src="./../components/menu/menu.css"></style>