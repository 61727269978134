import { IonContent, IonInput, IonButton, IonBackButton } from '@ionic/vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: "RequestTokenPage",
  components: {IonContent, IonInput, IonButton, IonBackButton },
  inheritAttrs: false
})
export default class RequestTokenPage extends Vue{
  error_login = false;

  /**
   * back to connexion page
   */
  back(){
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_photo_url');
    localStorage.removeItem('2fa');
    window.location = this.$mobile
  }
  /**
   * Connexion
   */
  goHome(tokenAuth){
    if(!tokenAuth)
      tokenAuth = "";
    if(tokenAuth.length == 6){
      this.$router.push({name: 'Home'});
      window.location = this.$mobile;
    }else 
      this.error_login = true;
  }
}
