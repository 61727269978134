import {Vue,Options} from 'vue-class-component';
import { useRoute } from 'vue-router';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonBackButton, IonButton } from '@ionic/vue';

@Options ({
  name: "InfosPage",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonBackButton },
  inheritAttrs: false,
})
export default class Info extends Vue{
  token = JSON.parse(localStorage.getItem('user_token'));
  dossier = null;
  fullname = "";
  email = "";
  phone = "";
  customFiels = "";
  company = "";
  route = useRoute(); 
  dossierId = this.route.params.id;

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.infoDossier()
  }

  /** 
   * Display info dossier
   */
  infoDossier() {
    this.$axios.get(this.$baseUrl + "/api/dossier/" + this.dossierId, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then((res) => {
      this.dossier = res['data'].data
      this.fullname = this.dossier.contact.full_name
      this.email = this.dossier.contact.email
      this.phone = this.dossier.contact.phone
      this.company = this.dossier.contact.company
      this.customFiels = this.dossier.contact.custom
    }).catch(error => {
      console.error("There was an error!", error);
    });
  }
}
