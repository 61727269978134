import { createRouter, createWebHistory } from "@ionic/vue-router"
import HomePage from "../views/HomePage.vue"
import LoginPage from "../views/LoginPage.vue"
import Messaging from "../views/MessagingPage.vue"
import Teams from "../views/TeamsPage.vue"
import Detail from "../views/DetailPage.vue"
import Stagelist from "../views/StagelistPage.vue"
import Todolist from "../views/TodolistPage.vue"
import Inbox from "../views/InboxPage.vue"
import Infos from "../views/InfosPage.vue"
import DossierItem from "../views/DossierItemPage.vue"
import RequestToken2FA from "../views/requestToken2FA.vue"

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/login/token",
    name: "Token",
    component: RequestToken2FA,
  },
  {
    path: "/messaging",
    name: "Messaging",
    component: Messaging,
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/detail/:id",
    name: "detail",
    component: Detail,
  },
  {
    path: "/todolist",
    name: "todolist",
    component: Todolist,
  },
  {
    path: "/stagelist",
    name: "stagelist",
    component: Stagelist,
  },
  {
    path: "/inbox/:id",
    name: "inbox",
    component: Inbox,
  },
  {
    path: "/infos/:id",
    name: "infos",
    component: Infos,
  },
  {
    path: "/dossier_item/:id",
    name: "dossier_item",
    component: DossierItem,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

/* Routing condition */
let isAuthenticated =JSON.parse(localStorage.getItem('user_token'));
let twoFA = JSON.parse(localStorage.getItem('2fa')); 
router.beforeEach((to, from, next) => {
  if(to.name !== 'Login' && !isAuthenticated && !twoFA) next({ name: 'Login' })
  else if( to.name == 'Home' && isAuthenticated && twoFA) {
    localStorage.removeItem('2fa');
    next({ name: 'Token' });
}
  else next()
})

export default router
