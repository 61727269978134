<template>
  <ion-content>
    <div class="backdrop" v-on:click="close()"></div>
    <div class="group">
      <ion-list radio-group>
        <div class="list-header flex jc-between al-center">
          <div>
            {{$t('more_options_title')}}
          </div>
          <div v-on:click="close()">
            <img src="../assets/imgs/oclose.svg" alt="">
          </div>
        </div>
        <ion-item  v-on:click="goInfos()" >
          <div class="start-slot" slot="start">
            <ion-icon name="person-outline"></ion-icon>
          </div>
          <ion-label>{{$t('more_options_infos')}}</ion-label>
        </ion-item>
        <ion-item  v-on:click="goMessaging()" >
          <div class="start-slot" slot="start">
            <ion-icon name="mail-outline"></ion-icon>
          </div>
          <ion-label>{{$t('more_options_messaging')}}</ion-label>
        </ion-item>
        <ion-item v-on:click="closeDossier()" v-if=" status != '5_closed' ">
          <div class="start-slot" slot="start">
            <ion-icon name="checkmark-circle-outline"></ion-icon>
          </div>
          <ion-label>{{$t('more_options_close')}}</ion-label>
        </ion-item>
        <ion-item v-on:click="reopenDossier()"  v-if=" status == '5_closed' ">
          <div class="start-slot" slot="start">
            <ion-icon name="arrow-undo-circle-outline" ></ion-icon>
          </div>
          <ion-label>{{$t('more_options_reopen')}}</ion-label>
        </ion-item>
      </ion-list>
    </div>
  </ion-content>
</template>

<script src="../components/moreOptions/moreOptions.js"></script>
<style scoped src="../components/moreOptions/moreOptions.css"></style>