import { loadingController,  IonHeader, IonIcon, IonBackButton, IonTitle, IonButton} from "@ionic/vue";
import { Vue, Options } from 'vue-class-component';

@Options ({
  name: "StagePage",
  components: { IonHeader, IonTitle, IonButton, IonIcon, IonBackButton },
  inheritAttrs: false,
  props: {
    current_dossier: { type: String },
  }
})
export default class StagelistPage extends Vue {
  loadingCtrl = loadingController
  stages= []; 
  token = JSON.parse(localStorage.getItem('user_token'));
  myLoading = null;
  loading = false;
  stage_dossier = "";
  dossierId = 0;


  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.stage_dossier = this.$store.state.current_dossier.stage
    if(this.stage_dossier) {
      this.showLoader();
     }
    this.dossierId = this.$store.state.current_dossier.id
    this.getStages()
  }

  /**
   * Get list stages
   */
  async getStages() {
    await this.$axios.get(this.$baseUrl + "/api/stages/dossier/" + this.dossierId, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => {
      this.stages = res["data"].data;
    }).catch(error => {
      console.error("There was an error!", error)
    });
  }
  
  /**
   *  Update stage
   */
  updateStage(stage) {
    this.stage_dossier  = stage.name;
    let input ="stage="+stage.name;
    this.$axios.put(this.$baseUrl + "/api/dossier/" + this.dossierId,input, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(() => {
     }).catch(error => {
      console.error("There was an error!", error)
    });
  }

  /** 
   * Show loader
   */
  async showLoader () {
    this.loading = true
    this.myLoading = await this.loadingCtrl.create({
      spinner: null,
      cssClass: "custom-loading",
      duration: 1000
    })
    await this.myLoading.present()
  }

  /** 
   * Go back 
   */
  goBack() {
    this.$router.back()
  }
}
