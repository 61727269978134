import MoreoptionsPage from "../../views/MoreoptionsPage.vue";
import { Vue, Options } from 'vue-class-component';
import { useRoute } from 'vue-router';
import { modalController, loadingController, IonContent, IonProgressBar, IonIcon, IonHeader, IonItem, IonBackButton, IonButton } from '@ionic/vue';

@Options ({
  name: "DetailPage",
  components: { IonContent, IonProgressBar,  IonHeader, IonItem, IonButton, IonIcon, IonBackButton },
  inheritAttrs: false,
  inject: ['message']
})
export default class DetailPage extends Vue{
  loadingCtrl = loadingController;
  modalCtrl = modalController;
  token = JSON.parse(localStorage.getItem('user_token'));
  dossier = {};
  todos = [];
  stage_string;
  todos_string;
  stages = [];
  selected_stage = "-";
  dossier_items = [];
  myLoading;
  modal = null;
  loading = false;
  route = useRoute(); 
  dossierId = this.route.params;
  scoring = 0;

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.loadDossier()
    let refreshId = setInterval(() => {
      this.scoring += 0.03;
      if (this.scoring >= this.dossier.scoring / 100) {
          this.scoring = this.dossier.scoring / 100 ;
          if(this.scoring == 0)
            this.scoring = 0.004;
          clearInterval(refreshId);
      } 
    }, 40);
  }

  /**
   * Load dossier information
   */
  async loadDossier(){
    if(!this.dossier.id){
      this.showLoader();
    } 

    await this.$axios.get(this.$baseUrl + "/api/dossier/"+this.dossierId.id, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(data => {
      if(data) {
        this.$store.commit('SET_DOSSIER', data['data'].data)
        this.dossier = data['data'].data;
        this.stage_string = this.dossier.stage 
        this.todos = this.dossier.todos;
        this.todos_string = this.todos.filter(todo => todo.done == 1).length + "/" + this.todos.length ;
        this.stages = this.dossier.stages;
        this.dossier_items = this.dossier.dossier_items;
        if(this.loading){
          this.loading = false;
          this.loadingCtrl.dismiss();
        }
      }
     }).catch(error => {
      console.error("There was an error!", error);
    });
  }
  
  /**
   * Go to todos list
   */
  goTodo(){
    this.$router.push({name: "todolist" });
  }

  /**
   * Go to stages list
   */
  goStage(){ 
    this.$router.push({name: "stagelist" });
  }

  /** 
   * Go back 
   */
  goBack() {
    this.$router.back()
  }

  /**
   * Open more option menu
   */
  async moreOptions() {
    this.modal = await this.modalCtrl.create({
      component: MoreoptionsPage,
      cssClass: 'my-custom-class',
      componentProps: { dossier: this.dossier, status: this.dossier.status },
    });

    this.modal.onDidDismiss()
      .then((res) => {
        if(res && res.data){
         this.loadDossier();
        }
    });
    return await this.modal.present();
  } 

  /**
   * Open uploads list
   */
  openDossierItem(item) {
    this.$router.push({path:'/dossier_item/' + JSON.stringify(item.id)}); 
  }

  /** 
   * Show loader
   */
  async showLoader() {
    this.loading = true;
    this.myLoading = await this.loadingCtrl.create({
      spinner: null,
      cssClass: 'custom-loading',
      duration:1000
    });
    await this.myLoading.present()
  }
}
