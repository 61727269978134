export default {
  "dossier_item_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files found"])},
  "more_options_application_reopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application reopened"])},
  "detail_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring"])},
  "more_options_application_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application closed"])},
  "more_options_reminder_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder sent"])},
  "todos_no_item_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No task found"])},
  "new_application_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Application"])},
  "stages_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "stages_no_item_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No defined stages"])},
  "teams_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your teams"])},
  "todos_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do list"])},
  "menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need Help?"])},
  "menu_inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
  "menu_teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch team"])},
  "messaging_no_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No conversation found"])},
  "messaging_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "more_options_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close application"])},
  "more_options_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete application"])},
  "more_options_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App infos"])},
  "home_no_item_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No application found"])},
  "more_options_messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View messages"])},
  "home_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write here to search"])},
  "more_options_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reminder"])},
  "home_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "not_defined_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not defined yet"])},
  "more_options_reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen application"])},
  "home_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "more_options_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More options"])},
  "inbox_new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your message"])},
  "new_application_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "inbox_no_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No message found"])},
  "new_application_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "inbox_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "new_application_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "inbox_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
  "new_application_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient first name"])},
  "infos_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
  "new_application_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient last name"])},
  "login_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "error_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
  "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalid email"])},
  "new_application_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "login_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email or password"])},
  "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget your password?"])},
  "login_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "login_login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your information to get log in"])},
  "login_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login_sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "login_sign_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need a Clustdoc account to be able to use the mobile app. Register from your computer on clustdoc.com."])},
  "tilte_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your authentication code"])},
  "token_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification token"])},
  "token_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This token is not valid"])},
  "login_check_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
  "menu_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "filters_4_pre_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre approved"])},
  "filters_5_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "filters_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "filters_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel filter"])},
  "filters_status_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All statuses"])},
  "filters_status_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "filters_template_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "filters_templates_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Processes"])},
  "filters_tags_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All tags"])},
  "filters_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range"])},
  "filters_title_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "comment_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your comment below"])},
  "details_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "details_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "details_todos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos list"])},
  "dossier_item_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
  "dossier_item_cancel_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel approval"])},
  "dossier_item_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "dossier_item_no_uploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No document available yet."])},
  "filters_0_not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Started"])},
  "filters_1_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
  "filters_3_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted"])}
}