<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button mode="ios" text="" default-href="" v-on:click="$router.back()"></ion-back-button>
      </ion-buttons>
      <ion-title mode="ios">{{ $t('stages_header') }} </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" scroll-y="false">
    <div class="the-list">
      <ion-item v-on:click="updateStage(stage)" v-for="stage in stages" :key="stage" lines="none">
        <ion-label>{{stage.name}} </ion-label>
        <div class="end-slot flex al-center" slot="end">
          <ion-icon name="checkmark-circle"  v-if="stage.name == stage_dossier" color="success"></ion-icon>
          <ion-icon name="ellipse-outline"  v-if="stage.name != stage_dossier"></ion-icon>
        </div>
      </ion-item>
    </div>
    <div class="ion-text-center no-item-image"  v-if=" !loading && (!stages || !stages.length)">
      <img src="../assets/imgs/no-item.png" alt="" style="width:40%">
      <p>
        {{ $t('stages_no_item_found') }}
      </p>
    </div>
  </ion-content>
</template>

<script src="../components/stage/stage.js"></script>
<style scoped src="../components/stage/stage.css"></style>