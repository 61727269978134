import FilterPage from "../../views/FilterPage.vue";
import NewapplicationPage from "../../views/NewApplication.vue";
import { loadingController, modalController, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabButton, IonInfiniteScrollContent, IonHeader, IonPage, IonIcon, IonToolbar, IonTitle, IonButtons, IonInput, IonSearchbar, IonButton, IonMenuButton, IonMenu, IonInfiniteScroll, IonCol, IonRow } from "@ionic/vue";
import { Vue, Options } from 'vue-class-component';

@Options ({
  name: "HomePage",
  components: { IonContent, IonHeader, IonPage, IonTitle, IonInput, IonSearchbar,IonFab, IonFabButton, IonButton, IonMenuButton, IonMenu, IonToolbar, IonButtons, IonIcon, IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent, IonCol, IonRow },
  inheritAttrs: false,
  
})
export default class HomeClass extends Vue {
  loadingCtrl = loadingController;
  modalCtrl = modalController;
  filter_all = "-ALL-";
  token = JSON.parse(localStorage.getItem("user_token"));
  dossiers = [];
  myLoading = null;
  modal = null;
  loading = false;
  show_loader = false;
  page_number = 1;
  filter_status = null;
  filter_template_id = 0;
  filter_tag_id = null;
  filter_start_date = null;
  filter_end_date = null;
  search_string = null;
  infinite_event = null;

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.loadDossiers(true)
  }

  /**
   * Load initial
   */
  loadInitial () {
    const show_loader = !(this.dossiers && this.dossiers.length)
    this.loadDossiers(show_loader)
    this.initFilters()
  }

  /**
   * On infinite scroll
   */
  doInfinite(event) {
    this.infinite_event = event
    this.loadMoreDossiers()
  }

  /**
   * Load more dossiers
   */
  loadMoreDossiers () {
    this.page_number++
    this.loadDossiers();
  }

  /**
   * Search items
   */
  searchItem (ev) {
    const data = ev.target.value
    this.page_number = 1
    if (data === "") {
      this.search_string = null
    }
    else if (data.length < 3 ) {
      return
    }
    this.search_string = data
    this.loadDossiers()
  } 

  /**
   * Init filters
   */
  initFilters () {
    this.filter_status = this.filter_all
    this.filter_template_id = this.filter_all
    this.tag = this.filter_all
    this.filter_start_date = this.filter_all
    this.filter_start_date = this.filter_all
  }

  /** 
   * get Users List
   */
  getUsersList (event) {
    event.target.disabled = true
    this.loadInitial()
    setTimeout(() => {
      event.target.complete()   
    }, 1500)
    setTimeout(() => {
      event.target.disabled = false
    }, 1500)
  }

  /**
   * Show loader
   */
  async showLoader () {
    this.loading = true
    this.myLoading = await this.loadingCtrl.create({
      spinner: null,
      cssClass: "custom-loading",
      duration:1000
    })
    await this.myLoading.present()
  }

  /**
   * Load dossiers
   */
  async loadDossiers (show_loader = false) {
    let url = "" 
    if (this.search_string) {
      url = this.$baseUrl + "/api/dossiers/search/" + this.search_string + "?page=" + this.page_number
    }
    else {
      if (this.page_number == 1 && show_loader) {
        this.showLoader()
      }
      url = this.$baseUrl + "/api/dossiers?page=" + this.page_number
      if (this.filter_status && this.filter_status != this.filter_all ) {
        url = url + "&status=" + this.filter_status
      }
      if (this.filter_template_id && this.filter_template_id != this.filter_all ) {
        url = url + "&template=" + this.filter_template_id
      }
      if (this.filter_tag_id && this.filter_tag_id != this.filter_all ) {
        url = url + "&tag=" + this.filter_tag_id
      }
      if (this.filter_start_date && this.filter_end_date && this.filter_start_date != this.filter_all && this.filter_end_date != this.filter_all) {
        url = url + "&start_date=" + this.filter_start_date + "&end_date=" + this.filter_end_date
      }
    }

    await this.$axios.get(url, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
      }).then(res => {
      if(res['data'] != null) {
        if (this.page_number == 1) {
          this.dossiers = res['data'].data;
        }
        else {
          for (let i = 0; i < res['data'].data.length - 1; i++) {
            this.dossiers.push(res['data'].data[i]);
          }
        }
        if (this.infinite_event) {
          this.infinite_event.target.complete()
        }
      }
      if (this.loading) {
        this.loadingCtrl.dismiss()
        this.loading = false
      }
    })
    .catch(error => {
      console.error("There was an error!", error)
    })
  }

  /**
   * Show filter modal
   */
  async showFilter () {
    this.modal = await this.modalCtrl.create({
      component: FilterPage,
      cssClass: 'my-custom-class',
      componentProps: { status: this.filter_status, template_id: this.filter_template_id },
    })
    
    this.modal.onDidDismiss()
    .then(async (res) => {
      if (res && res.data) {
        if (!res.data.status && !res.data.template_id && !res.data.tag && !res.data.start_date && !res.data.end_date) {
          this.initFilters()
        }
        else {
          this.filter_status = res.data.status
          this.filter_template_id = res.data.template_id
          this.filter_tag_id = res.data.tag
          this.filter_start_date = res.data.start_date
          this.filter_end_date = res.data.end_date
        }
        this.search_string = null
        this.page_number = 1
        await  this.loadDossiers(true)
      }
    }) 
    return await this.modal.present()
  }

  /**
   * Show new application modal
   */
  async showNewApplication () {
    const modal = await this.modalCtrl.create({
      component: NewapplicationPage,
      cssClass: "my-custom-class"
    })

    modal.onDidDismiss()
      .then((res) => {
        if (res && res.data) {
          this.page_number = 1
          this.search_string = null
          this.initFilters()
          this.loadDossiers(true)
        }
      })
    return await modal.present()
  }

 /**
  * Open dossier item 
  */
  openDetails (id) {
    this.$router.push({path:"/detail/" + id}) 
  }
}


