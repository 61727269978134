<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button mode="ios" text="" default-href="" v-on:click="$router.back()"></ion-back-button>
      </ion-buttons>
      <ion-title mode="ios"> {{ $t('inbox_title') }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content ref="content" class="ion-padding-vertical" :scroll-events="true">
    <div v-for=" message in messages" :key="message">
      <ion-row>
        <ion-col size="1.8" class="avatar flex jc-start">
          <img v-if="message.author_type == 'contact' " src="../assets/imgs/avatar.png" alt="">
          <img v-if="message.author_type != 'contact' " :src="message.user.photo_url">
        </ion-col>
        <ion-col size="10.2" class="main-second">
          <ion-row class="first-row">
            <ion-col size="8" class="flex al-center">
              <h4>{{message.author_name}}</h4>
            </ion-col>
            <ion-col size="4" class="day al-center flex jc-end">
              <p>{{ $dateFormat(message.created_at) }}</p>
            </ion-col>
          </ion-row>
          <ion-row class="second-row">
            <ion-col  size="12" class="req flex al-center jc-between">
              <p> {{message.body}}</p>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
    <div class="ion-text-center no-item-image"  v-if="!loading && (!messages || !messages.length)">
      <img src="../assets/imgs/no-message.png" alt="" style="width:40%">
      <p>
        {{ $t('messaging_no_message') }}
      </p>
    </div>
  </ion-content>
  <ion-footer mode="md" >
    <ion-toolbar mode="md">
      <ion-textarea v-model="message" rows="2" :placeholder="$t('inbox_new_message') " ></ion-textarea>
      <ion-row>
        <ion-col size="12" class="ion-no-padding ion-text-right">
          <ion-button v-on:click="postMessage()" fill="clear" >
            {{ $t('inbox_send')}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</template>

<script src="../components/inbox/inbox.js"></script>
<style scoped src="../components/inbox/inbox.css"></style>