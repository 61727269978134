import { useRoute } from 'vue-router';
import  router  from '../../router';
import { Vue, Options } from 'vue-class-component';
import { toastController, modalController, IonContent, IonList, IonItem, IonLabel, IonIcon, IonBackButton, IonTitle, IonButton} from "@ionic/vue";

@Options({
  name: "MoreOptionsPage",
  components: { IonContent,IonList, IonItem, IonLabel, IonTitle, IonButton, IonIcon, IonBackButton },
  props: {
    status: { type: String, default: 'Super Modal' }
  },
  inheritAttrs: false
})
export default class MoreoptionsPage extends Vue{
  toastController= toastController;
  modalCtrl= modalController;
  token = JSON.parse(localStorage.getItem('user_token'));
  route = useRoute(); 
  dossierId = this.route.params.id;

  /** 
   * Write a message in a popup 
   */
  async presentToast(message) {
    let toast = await this.toastController.create({
      message:message,
      duration: 2000,
      color:"success",
      position:"top"
    });
    toast.present();
  }

  /** 
   * Close dossier
   */
  closeDossier(){
    this.$axios.post(this.$baseUrl + "/api/dossier/" + this.dossierId + "/close", null, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(() => {
      this.modalCtrl.dismiss(true);
      this.presentToast(this.$t('more_options_application_closed'));
    }).catch(error => {
      console.error("There was an error!", error);
    });
  }

  /** 
   * Reopen dossier
   */
  reopenDossier(){
    this.$axios.post(this.$baseUrl + "/api/dossier/" + this.dossierId + "/reopen", null, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(() => {
      this.modalCtrl.dismiss(true);
      this.presentToast(this.$t('more_options_application_reopened'));
    }).catch(error => {
      console.error("There was an error!", error);
    });
  }

  /** 
   * Go Messaging
   */
  goMessaging() {
    router.push({path :"/inbox/" + this.dossierId});
    this.modalCtrl.dismiss(true);
  }

  /** 
   * Go info 
   */
  goInfos() {
    router.push({path: "/infos/" + this.dossierId});
    this.modalCtrl.dismiss(true);
  }

  /**
   * Close modal 
   */
  close() {
    this.modalCtrl.dismiss();
  }
}
