import  CommentPage  from '../../views/CommentPage.vue';
import { useRoute } from 'vue-router';
import { Vue, Options } from 'vue-class-component';
import { modalController, IonContent, IonIcon, IonHeader, IonItem, IonBackButton, IonButton } from '@ionic/vue';

@Options ({
  name: "DossierItemPage",
  components: { IonContent, IonHeader, IonItem, IonButton, IonIcon, IonBackButton },
  inheritAttrs: false
})
export default class DossierItemPage extends Vue{
  modalCtrl= modalController;
  baseUrl = "https://app.clustdoc.com";
  token= JSON.parse(localStorage.getItem('user_token'));
  comments = '';
  uploads = [];
  category= "approve";
  route = useRoute(); 
  dossier_item=null;
  dossier_item_id = this.route.params.id;
  status = null;
  note = null;
  title="";

  /** 
   * Called right before the component is to be mounted.
   */
  beforeMount() {
    this.getDossierItem()
  }

  /** 
   * Get dossier item
   */
  async getDossierItem() {
    await this.$axios.get(this.$baseUrl+"/api/dossierItem/"+this.dossier_item_id, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => {
      if(res) {
        this.dossier_item = res['data'].data;
        this.status = this.dossier_item.status
      }
    }).catch(error => {
      console.error("There was an error!", error);
    });
    this.uploads = this.dossier_item.uploads;
    this.title = this.dossier_item.title
  }

  /** 
   * Show comment
   */
  async showComment(){
    const modal = await this.modalCtrl.create({
      component: CommentPage,
      cssClass: 'my-custom-class'
    });
    
    await modal.present();
    modal.onDidDismiss()
    .then((res) => {
      if(res && res.data){        
        this.addComments(res.data);
      } 
    });
  }

  /** 
   * Add comment
   */
  addComments(comment) {
    let comment_obj = {
      "note": comment
    }
    this.$axios.post(this.$baseUrl+"/api/dossierItem/"+this.dossier_item.id+"/edit-note", comment_obj, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => {
      if(res) {
        this.note = comment;
      }
    }).catch(error => {
      console.error("There was an error!", error);
    });
  }

  /** 
   * diplay upload
   */
  displayUpload(upload){
    window.open(upload.url) ;
  }

  /** 
   * Approve item
   */
  approve() {
    this.$axios.post(this.$baseUrl + "/api/dossierItem/"+this.dossier_item.id+"/approve", null, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => {
      if(res && res['data']){
        this.status =res['data'].data.status
      }
    }).catch(error => {
        console.error("There was an error!", error);
    });
  }

  /* 
   * Cancel approval
   */
  cancel_approval() {
    this.$axios.post(this.$baseUrl + "/api/dossierItem/"+this.dossier_item.id+"/cancel-approval", null, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => {
      if(res && res['data']){
        this.status =res['data'].data.status
      }
    }).catch(error => {
      console.error("There was an error!", error);
    });
  }
}
