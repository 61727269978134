<template>
  <ion-content>
    <div class="backdrop" v-on:click="close()"></div>
    <div class="group">
      <ion-list>
        <div class="list-header flex jc-between al-center">
          <div>
            {{ $t('filters_title_filters' )}} 
          </div>
          <div v-on:click="close()">
            <img src="../assets/imgs/oclose.svg" alt="">
          </div>
        </div>
        <ion-item>
          <ion-select v-model="current_template_id" :placeholder="$t('filters_templates_all')" interface="action-sheet" mode="ios">
            <ion-select-option v-for=" template in templates" :key="template" :value="template.id">{{template.title}}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-select v-model="current_status" :placeholder=" $t('filters_status_all') " interface="action-sheet" mode="ios">
            <ion-select-option v-for="st in statuses" :key="st" :value="st">{{ $t('filters_'  + st) }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-select v-model="current_tag_id" :placeholder=" $t('filters_tags_all') " interface="action-sheet" mode="ios">
            <ion-select-option v-for="tag in tags" :key="tag" :value="tag.id">{{ tag.name }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item >
          <ion-col size="5">
            <ion-input v-model="filter_start_date" type="date" > </ion-input>
          </ion-col>
          <ion-col size="1">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </ion-col>
          <ion-col size="5">
            <ion-input v-model="filter_end_date" type="date"> </ion-input>
          </ion-col>
          <!-- <ion-input v-if="disable_filter_range == true" v-model="filter_start_date" type="date" :disabled="true"> </ion-input>
          <ion-input v-else v-model="filter_start_date" type="date" style="margin-bottom:0px;margin-right:0px;margin-top:0px"> </ion-input>
          <ion-icon name="arrow-forward-outline"></ion-icon>
          <ion-input class="date-end" v-if="disable_filter_range == true" v-model="filter_end_date" type="date" :disabled="true"> </ion-input>
          <ion-input v-else class="date-end" v-model="filter_end_date" type="date" > </ion-input>
          <ion-checkbox v-model="filter_range" slot="end" checked="true" v-on:click="disableRange()"> </ion-checkbox > -->
       </ion-item>
      </ion-list>
      <ion-button expand="full" color="success" v-on:click="applyFilter(current_status,current_template_id,current_tag_id,filter_start_date,filter_end_date)">{{ $t('filters_button') }}</ion-button>
    </div>
  </ion-content>
</template>

<script src="./../components/filter/filter.js"></script>
<style scoped  src="./../components/filter/filter.css"></style>
