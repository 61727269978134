<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title mode="ios">
        {{$t('teams_title') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-top" scroll-y="false">
    <ion-refresher @ionRefresh="getUsersList($event)" slot="fixed" pullFactor="0.5" pullMin="100" pullMax="200">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div v-for="item in teams" :key="item" class="the-list">
      <ion-item v-on:click="teamUpdate(item.id)" lines="none">
        <ion-label>
          {{item.name}}
        </ion-label>
        <div v-if="item.user_current_team==true" class="end-slot" slot="end" >
          <ion-icon name="checkmark-circle" color="success"></ion-icon>
        </div>
      </ion-item>
    </div>
  </ion-content>  
</template>

<script src="../components/teams/teams.js"></script>
<style scoped src="../components/teams/teams.css"></style>