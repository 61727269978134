import { loadingController } from '@ionic/vue';
import { IonHeader, IonIcon, IonBackButton, IonTitle, IonButton} from "@ionic/vue";
import {Vue,Options} from 'vue-class-component';

@Options ({
  name: "TodoPage",
  components: { IonHeader, IonIcon, IonBackButton, IonTitle, IonButton },
  inheritAttrs: false
})
export default class TodolistPage extends Vue {
  loadingCtrl = loadingController
  token = JSON.parse(localStorage.getItem('user_token'));
  myLoading = null;
  loading = false;
  todos = null;

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.todos = this.$store.state.current_dossier.todos
    this.dossierId = this.$store.state.current_dossier.id
  }

  /**
   * Update todo list
   */
  updateToDo(item) {
    item.done = 1 - item.done;
    let data  ="done="+item.done;
    this.$axios.put(this.$baseUrl + "/api/todo/" + item.id, data, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
      }).then(() => {
    }).catch(error => {
      console.error("There was an error!", error)
    });
  }
}
