<template> 
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title mode="ios">
        {{ $t('messaging_title') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-top">
    <ion-refresher @ionRefresh="getUsersList($event)" slot="fixed" :pull-factor="0.5" :pull-min="100" :pull-max="200">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="the-list">
      <div v-for="dossier in conversations" :key="dossier" class="card" v-on:click="goMessaging(dossier)">
        <ion-row>
          <ion-col size="1.8" class="thumb flex jc-start">
            <div class="inside-holder flex al-center jc-center">
              <h4>{{dossier.contact.initials}}</h4>
            </div>
          </ion-col>
          <ion-col size="10.2" class="main-second">
            <ion-row class="first-row">
              <ion-col size="8" class="flex al-center">
                <h4>{{dossier.contact.full_name}}</h4>
              </ion-col>
              <ion-col size="4" class="day al-center flex jc-end">
                <p>{{ $dateFormat(dossier.last_read_message_at) }}</p>
              </ion-col>
            </ion-row>
            <ion-row class="second-row">
              <ion-col size="6" class="req flex al-center jc-between">
                <h5 class="ion-text-wrap">{{dossier.title}}</h5>
              </ion-col>
              
              <ion-col size="1"></ion-col>
              <ion-col size="5" class="status flex jc-between">
                <h5></h5>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </ion-col>
            </ion-row>
            <ion-row class="third-row">
              <ion-col  size="8" class="req flex al-center jc-between">
                <p> {{truncate_string(dossier.latest_message.body,150)}}</p>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
      <div class="ion-text-center no-item-image"  v-if="!loading && (!conversations || !conversations.length)">
        <img src="../assets/imgs/no-message.png" alt="" style="width:40%">
        <p>
          {{ $t('messaging_no_message')}}
        </p>
      </div>
    </div>
  </ion-content>
</template>

<script src="./../components/messaging/messaging.js"></script>
<style scoped src="./../components/messaging/messaging.css"></style>