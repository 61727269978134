import { loadingController } from '@ionic/vue';
import { IonHeader, IonIcon, IonPage, IonTitle, IonButton, IonMenuButton, IonMenu} from "@ionic/vue";
import {Vue,Options} from 'vue-class-component';

@Options({
  name: "TeamsPage",
  components: { IonHeader, IonPage, IonTitle, IonButton, IonMenuButton, IonMenu, IonIcon },
  inheritAttrs: false
})

export default class TeamsPage extends Vue{

  loadingCtrl =  loadingController;
  token = JSON.parse(localStorage.getItem('user_token')); 
  teams = [];
  myLoading = null;

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.loadInitial()
  }

  /** 
   * get Users List
   */
  getUsersList(event) {
    event.target.disabled = true;
    this.loadInitial();
    setTimeout(() => {
      event.target.complete();   
    }, 1500);
    setTimeout(() => {
      event.target.disabled = false;
    }, 1500);
  }

  /**
   * Show loader
   */
  async showLoader() {
    this.myLoading = await this.loadingCtrl.create({
      spinner: null,
      cssClass: 'custom-loading',
      duration: 1000
    });
    await this.myLoading.present()
  }

  /**
   * Load initial
   */
  async loadInitial(){
    this.showLoader();
    await this.$axios.get(this.$baseUrl + "/api/teams?", {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(data => {
      if(data) {
        this.teams = data['data'].data;
        this.loadingCtrl.dismiss();
      }
      else {
        alert("Invalid Login details")
        this.loadingCtrl.dismiss();
      }
     }).catch(error => {
      console.error("There was an error!", error)
    })
  }

  /**
   * Update team
   */
  async teamUpdate(team) {
    this.showLoader();
    await this.$axios.get(this.$baseUrl + "/api/team/"+team+"/switch?", {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(data => {
      if(data) {
        this.loadInitial();
        this.loadingCtrl.dismiss();
      }
    }).catch(error => {
      console.error("There was an error!", error)
    })
  }
}
