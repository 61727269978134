<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button mode="ios" text="" default-href="" v-on:click="$router.back()"></ion-back-button>
      </ion-buttons>
      <ion-title mode="ios">
        {{ title }}
      </ion-title>
      <ion-buttons slot="end">
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-top dossier-item" scroll-y="false">
    <div class="ion-padding-top uploads-list"  v-if="uploads && uploads.length">
      <p class="docs-counter">{{$t('dossier_item_counter') }} : {{ uploads.length }}</p>
      <div class="alert alert-warning  text-center" v-if="note && note !='' ">
        {{note}} 
      </div>
      <ion-item detail="true" detailIcon="eye-outline" v-for=" upload in uploads" :key="upload" v-on:click="displayUpload(upload)">
        <ion-label>
          <h2>{{upload.title}}</h2>
          <p>{{upload.created_at }}</p>
        </ion-label>
      </ion-item>
    </div>
    <div class="ion-text-center no-item-image"  v-if="!uploads || !uploads.length">
      <div class="alert alert-warning text-center" v-if="note && note !='' ">
        {{note}} 
      </div>
      <img src="../assets/imgs/no-item.png" alt="" style="width:40%">
      <p>
        {{$t('dossier_item_no_uploads' )}}
      </p>
    </div>
  </ion-content>
  <ion-footer mode="md" >
    <ion-toolbar mode="md">
      <ion-row v-if="status != '2_approved'">
      <ion-col size="6">
        <ion-button v-on:click="approve()" expand="block" id="approve">
          {{$t('dossier_item_approve' )}}  
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button expand="block" id="comment" v-on:click="showComment()">
          {{$t('dossier_item_comment' )}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row  v-if="status == '2_approved'">
      <ion-col size="12" class="ion-text-center">
        <ion-button expand="block" color="light" id="cancel-approval" v-on:click="cancel_approval()">
          {{$t('dossier_item_cancel_approval' )}}
        </ion-button>
      </ion-col>
    </ion-row>
    </ion-toolbar>
  </ion-footer>
</template>

<script src="../components/dossier_item/dossier_item.js"></script>
<style scoped src="../components/dossier_item/dossier_item.css"></style>