<template>
  <ion-content>
    <div class="content">
      <ion-grid style="height: 100%">
        <ion-row class="ion-justify-content-center ion-align-items-center" style="height: 100%">
          <div class="holder">
            <div class="logo-holder ion-text-center flex al-center jc-center">
              <img src="./../assets/imgs/logo.svg" alt=""/>
            </div>
            <div class="inside-content">
              <div class="title">
                <h3> {{ $t( 'login_login') }} </h3>
                <div class="head-styler flex">
                  <div class="st1"> </div>
                  <div class="st2"></div>
                </div>
                <p>{{ $t('login_login_msg') }}</p>
              </div>
              <div class="the-form">
                <ion-item lines="none">
                  <div class="start-slot" slot="start">
                    <img src="./../assets/imgs/icn-person.svg" alt="">
                  </div>
                  <ion-input type="text" v-model="userEmail" :placeholder="$t('login_email')"></ion-input>
                </ion-item>
                <ion-item lines="none">
                  <div class="start-slot" slot="start">
                    <img src="./../assets/imgs/icn-lock.svg" alt="">
                  </div>
                  <ion-input type="password" v-model="password" :placeholder="$t('login_password')"></ion-input>
                </ion-item>
              </div>
              <a class="forget-password" :href="href_forget_password">{{ $t('forget_password') }}</a>
              <p class="ion-text-center error"  v-if="error_login==true">{{ $t('login_error_message' ) }}</p>
              <div class="btn-holder ion-padding-vertical">
                <ion-button expand="block" v-on:click="goHome(userEmail,password)">
                {{ $t('login_sign_in' ) }}
                </ion-button>
              </div>  
              <div class="description">
                <p class="ion-text-wrap">
                  {{ $t('login_sign_info' ) }}
                </p>
              </div>
            </div>
          </div>
        </ion-row>
      </ion-grid>
    </div>
  </ion-content>
</template>

<script src="./../components/login/login.js"></script>
<style scoped src="./../components/login/login.css"></style>
