import { IonContent, IonInput } from '@ionic/vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: "LoginPage",
  components: {IonContent, IonInput },
  inheritAttrs: false
})
export default class LoginPage extends Vue{
  error_login = false;
  href_forget_password = "";

  /**
   * Called after the component has been mounted
   */
  mounted() {
    this.testRegisteredToken();
    this.href_forget_password = this.$baseUrl + "/password/reset";
  }

  /**
   * Test if token is ready stocked or not
   */
  testRegisteredToken(){
    let token = JSON.parse(localStorage.getItem('user_token'));
    let twoFA = JSON.parse(localStorage.getItem('2fa'));
    if(token && !twoFA){ 
      this.$axios.get(this.$baseUrl + "/api/status", {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then( function(){
        this.$router.push({name: 'Home'});
      }).catch(error => {
        console.error("There was an error!", error);
      });
    }
  }

  /**
   * Connexion
   */
  goHome(userEmail,password){
    //this.testRegisteredToken();
    let loginDetails = "email="+userEmail+"&password="+password;
    this.error_login=false;
    if(userEmail == "" && password == "") 
      this.error_login = true;
    else {
      this.$axios.post(this.$baseUrl + "/api/auth", loginDetails, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then(response => {
        if(response.data['token']) {
          var user= {
            'user_token' : response.data['token'],
            'user_email': userEmail,
            'user_name' : response.data['user']['name'],
            'user_photo_url': response.data['user']['photo_url'],
            '2fa': response.data['user']['uses_two_factor_auth']
          }
          this.$store.commit('SET_USER', user)

          for (var i in user){
            localStorage.setItem(i, JSON.stringify(user[i]))
          }
          if(user['uses_two_factor_auth'] == true) {
            this.$router.push({path: '/login/token'});
          }
          else {
            this.$router.push({name: 'Home'});
          }
          window.location=this.$mobile
        }
        else {
          this.error_login = true;
        }
      }).catch(error => {
        console.error("There was an error!", error);
        this.error_login = true;
      });
    }
  }
}
