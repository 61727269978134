<template>
  <ion-app>
    <MenuPage></MenuPage>
    <router-view />
  </ion-app>
</template>

<script>
import { IonApp } from '@ionic/vue';
import { defineComponent } from 'vue';
import MenuPage from './views/MenuPage.vue'

export default defineComponent({
  name: 'App',
  components: { IonApp, MenuPage },
  data() {
    
    // Apply browser language
    if(navigator.language.split('-')[0] == "fr")
      this.$i18n.locale = "fr";
    else
      this.$i18n.locale = "en";
    return {}; 
  }
});
</script>
<style src="./theme/global.css"></style>