
<template>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button> 
      </ion-buttons>
      <ion-title mode="ios">
        {{ $t('home_title') }}  
      </ion-title>
      <ion-buttons slot="end">
        <ion-button v-on:click="showFilter()">
          <ion-icon name="options" style="color:white"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <div class="search-holder">
      <ion-searchbar
        mode="md"
        :placeholder="$t('home_search_placeholder')"
        inputmode="text"
        type="decimal"
        showCancelButton="never"
        debounce="250"
        @ionInput="searchItem($event)">
      </ion-searchbar>
    </div> 
  </ion-header>
  <div style="height:100%">
    <ion-content class="ion-padding-vertical">
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-on:click="showNewApplication()" >
      <ion-fab-button>
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-refresher @ionRefresh="getUsersList($event)" slot="fixed" :pull-factor="0.5" :pull-min="100" :pull-max="200">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="the-list">
      <div v-for="dossier in dossiers" :key="dossier" class="card">
        <ion-row v-on:click="openDetails(dossier.id)">
          <ion-col size="1.8" class="thumb flex jc-start">
            <div class="inside-holder flex al-center jc-center">
              <h4>{{dossier.contact.initials}}</h4>
            </div>
          </ion-col>
          <ion-col size="10.2" class="main-second">
            <ion-row class="first-row">
              <ion-col size="8" class="flex al-center">
                <h4>{{dossier.contact.full_name}}</h4>
              </ion-col>
              <ion-col size="4" class="day al-center flex jc-end" >
                <p> {{ $dateFormat(dossier.updated_at) }}</p>
              </ion-col>
            </ion-row>
            <ion-row class="second-row">
              <ion-col size="6" class="req flex al-center jc-between">
                <h5 class="ion-text-wrap">{{dossier.title}}</h5>
              </ion-col>
              <ion-col size="1">
                <div :style="{'background-color': dossier.status_color}"   class="dot"> </div>            
              </ion-col>
              <ion-col size="5" class="status flex jc-between">
                <h5>{{dossier.status_string_full}}</h5>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </ion-col>
            </ion-row>
            <ion-row class="third-row">
              <ion-col v-if="dossier.contact.custom != undefined" size="8" class="req flex al-center jc-between">
                <h5>{{dossier.contact.custom}}</h5>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
      <div class="ion-text-center no-item-image"  v-if="!loading && (!dossiers || !dossiers.length)">
        <img src="./../assets/imgs/no-item.png" alt="" style="width:40%">
        <p> {{ $t('home_no_item_found' ) }} </p>
      </div>   
    </div>
    <ion-infinite-scroll threshold="100px" @ionInfinite="doInfinite($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-content>
  </div>
</template>

<script src="./../components/home/home.js"></script>
<style scoped  src="./../components/home/home.css"></style>